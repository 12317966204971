import React, { useState, useEffect, useContext } from "react";

const EliminarPedido = (props) => {
  return props.item.tienepedido == 1 ? null : (
    <React.Fragment>
      <button
        className="btn-eliminar"
        onClick={() => props.handleEliminar(props.item)}
      >
        Eliminar
      </button>
    </React.Fragment>
  );
};

export default EliminarPedido;
