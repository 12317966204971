import React, { useState, useEffect, Fragment } from 'react';

import * as Constants from '../../constants/global';
import axios from 'axios';

const Filtros = (props) => {
    
    const [agencias, setAgencias] = useState([{codigo:"-1", nombre:"No Asignado"}]);
    const [regionales, setRegionales] = useState([{codigo:"-1", nombre:"No Asignado"}]);
    const [zonas, setZonas] = useState([{codigo:"-1", nombre:"No Asignado"}]);

    const [usuarios, setUsuarios] = useState([{codigo:"-1", nombre:"--Todos--"}]);

    const [tipoSucursal, setTipoSucursal] = useState("N");
    const [fecha, setFecha] = useState();
    const [fechaInicial, setFechaInicial] = useState();
    const [fechaFinal, setFechaFinal] = useState();

    let usuario = localStorage.getItem("usuario");
    let tipoUsuario = localStorage.getItem("tipoUsuario");

    useEffect(() => {

        setFechaInicial(Constants.getDate(0, "-"))
        setFechaFinal(Constants.getDate(0, "-"))
        
        loadRegionales();

    }, [])

    const loadRegionales = () => {
        let url = Constants.URL_BASE + Constants.WSFILTROREGIONAL;

        let data = {
            "Codigo" : usuario,
            "TipoUsuario" : tipoUsuario
        }

        axios.post(url, data)
        .then(res => {

            let listData = JSON.parse(res.data)

            listData = listData.length > 0 ? listData : [{codigo:"-1", nombre:"No Asignado"}];

            setRegionales(listData);

            loadAgencias(listData[0].codigo || null);

        });
    }

    const loadAgencias = (regional) => {

        let url = Constants.URL_BASE + Constants.WSFILTROAGENCIA;

        let data = {
            "Codigo" : usuario,
            "TipoUsuario" : tipoUsuario,
            "Regional" : regional || "",
        }

        axios.post(url, data)
        .then(res => {

            let listData = JSON.parse(res.data)

            listData = listData.length > 0 ? listData : [{codigo:"-1", nombre:"No Asignado"}];
            
            setFecha(Constants.getDate(0, "-"))
            setAgencias(listData);
            loadZonas(regional, listData[0].codigo || null);

        });
    }

    const loadZonas = (regional, agencia) => {
        let url = Constants.URL_BASE + Constants.WSFILTROZONA;

        let data = {
            "Codigo" : usuario,
            "TipoUsuario" : tipoUsuario,
            "Regional" : regional || "",
            "Agencia" : agencia || ""
        }

        axios.post(url, data)
        .then(res => {

            let listData = JSON.parse(res.data)

            listData = listData.length > 0 ? listData : [{codigo:"-1", nombre:"No Asignado"}];

            setZonas(listData);

            loadUsuarios(agencia, listData[0].codigo || null);

        });
    }

    const loadUsuarios = (agencia, zona) => {
        let url = Constants.URL_BASE + Constants.WSFILTROUSUARIO;

        let data = {
            "Codigo" : usuario
        }

        axios.post(url, data)
        .then(res => {

            let listData = JSON.parse(res.data)

            listData = listData.length > 0 ? listData : [{codigo:"-1", nombre:"--Todos--"}];

            setUsuarios(listData);

            props.handleFiltros(tipoSucursal, agencia, zona, listData[0].codigo);

        });
    }

    const handleFecha = (item) => {
        setFecha(fecha);
        props.handleFecha(item);
    }

    const handleFechaInicial = (item) => {
        setFechaInicial(item);
        props.handleFechaInicial(item);
    }

    const handleFechaFinal = (item) => {
        setFechaFinal(item);
        props.handleFechaFinal(item);
    }

    const handleSucursal = (item) => {
        setTipoSucursal(item);
        props.handleTipoSucursal(item);
    }

    const handleRegional = (item) => {
        props.handleRegional(item);
        loadAgencias(item);
    }

    const handleAgencia = (item) => {
        props.handleAgencia(item);

        let regional = "-1";

        if (props.habilitarRegional){
            let ddlRegional = document.getElementById("filtroRegional");
            regional = ddlRegional.options[ddlRegional.selectedIndex].value;
        }

        loadZonas(regional, item);
    }
    
    const handleZona = (item) => {
        props.handleZona(item);
    }

    const handleUsuario = (item) => {
        props.handleFiltroUsuario(item);
    }

    return (
        <Fragment>
            <div className="filtro">
                <div style={{ backgroundColor:'white', width:'100%', display:'flex' }}>
                    <ul id="scroll-categoria">

                        { props.habilitarFecha ? (
                            <input 
                            type="date" 
                            id="fecha"
                            onChange={e => handleFecha(e.target.value)}
                            defaultValue={fecha}
                            min={Constants.getDate(0, "-")} />
                        ) : null }

                        { props.habilitarFechaInicial ? (
                            <input 
                            type="date" 
                            id="fechaInicial"
                            onChange={e => handleFechaInicial(e.target.value)}
                            defaultValue={fechaInicial} />
                        ) : null }

                        { props.habilitarFechaFinal ? (
                            <input 
                            type="date" 
                            id="fechaFinal"
                            onChange={e => handleFechaFinal(e.target.value)}
                            defaultValue={fechaFinal}/>
                        ) : null }

                        { props.habilitarTipo ? (

                            <select className={"select-css"} onChange={(e) => handleSucursal(e.target.value)}>
                                <option value="N" >Clientes</option>
                                <option value="S" >Sucursal</option>
                            </select>

                        ) : null }

                        {props.habilitarUsuario ? ( 
                            <select className={"select-css"} onChange={(e) => handleUsuario(e.target.value)} id={"filtroUsuario"} >
                                {usuarios.map(function(item, i){
                                    return (
                                        <option value={item.codigo} key={i}>{item.nombre}</option>
                                    );
                                })}
                            </select>
                        ): null}

                        {props.habilitarRegional ? (
                            <select className={"select-css"} onChange={(e) => handleRegional(e.target.value)} id={"filtroRegional"} >
                                {regionales.map(function(item, i){
                                    return (
                                        <option value={item.codigo} key={i}>{item.nombre}</option>
                                    );
                                })}
                            </select>
                        ): null}

                        {tipoSucursal == "N" && props.habilitarAgencia ? (
                            <select className={"select-css"} onChange={(e) => handleAgencia(e.target.value)} id={"filtroAgencia"} >
                                {agencias.map(function(item, i){
                                    return (
                                        <option value={item.codigo} key={i}>{item.nombre}</option>
                                    );
                                })}
                            </select>
                        ): null}

                        {tipoSucursal == "N" && props.habilitarZona ? (
                            <select className={"select-css"} onChange={(e) => handleZona(e.target.value)} id={"filtroZona"}>
                                {zonas.map(function(item, i){
                                    return (
                                        <option value={item.codigo} key={i}>{item.nombre}</option>
                                    );
                                })}
                            </select>
                        ): null}
                    </ul>
                </div>
            </div>
        </Fragment> 
    )
}


export default Filtros;