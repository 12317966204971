import React, { useState, useEffect } from 'react';

import Filtros from '../navbar/Filtros';
import Cliente from './Cliente';
import EstadisticasAgendamiento from './EstadisticasAgendamiento';

import NavbarInicial from '../navbar/NavbarInicial';

import {ReactComponent as Search} from '../../assets/icon/search.svg';

import { withRouter } from 'react-router-dom';
import TabbarInicial from '../navbar/TabbarInicial';

const ListadoClientes = () => {

  const tipoUsuario = localStorage.getItem("tipoUsuario")

    const [search, setSearch] = useState();
    const [tipoSucursal, setTipoSucursal] = useState();
    const [regional, setRegional] = useState();
    const [agencia, setAgencia] = useState();
    const [zona, setZona] = useState();
    const [filtroUsuario, setFiltroUsuario] = useState();

    const [fechaInicial, setFechaInicial] = useState();
    const [fechaFinal, setFechaFinal] = useState();

    const handleTipoSucursal = item => {
        setTipoSucursal(item);
    }

    const handleRegional = item => {
        setRegional(item);
    }
    
    const handleAgencia = item => {
        setAgencia(item);
    }

    const handleZona = item => {
        setZona(item);
    }

    const handleFiltroUsuario = item => {
        setFiltroUsuario(item);
    }

    const handleFiltros = (tipoSucursal, agencia, zona, filtroUsuario ) => {
        setTipoSucursal(tipoSucursal)
        setAgencia(agencia);
        setZona(zona);
        setFiltroUsuario(filtroUsuario);
    }

    const handleKeyPress = (e) => {
        if(e.key === "Enter"){
            handleBuscarCliente()
        }
    }

    const handleBuscarCliente = () => {
        let busqueda = document.getElementById("search").value
        setSearch(busqueda)
    }

    const handleFechaInicial = item => {
        setFechaInicial(item);
    }

    const handleFechaFinal = item => {
        setFechaFinal(item);
    }

    useEffect(() => {
        let sucursal = localStorage.getItem("sucursal");
        localStorage.removeItem(sucursal);
    }) 

    return (
        <React.Fragment>
            <div className="navbar">
                <div className="navbar-header">

                    <NavbarInicial item={6}/>
                    <div className="input-search">
                        <input 
                            type="text" 
                            placeholder="Buscar cliente..."
                            name="search"
                            id="search"
                            onKeyPress={handleKeyPress}
                            // onChange={(e) => setSearch(e.target.value)}
                            // value={search}>
                            >
                        </input>
                        <div className="button-Search">
                            <button onClick={() => handleBuscarCliente()}><Search className="svg-una-capa" style={{width:"25px", height:"25px"}}/></button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{width:"100%"}}>
                <div className="main">
                    <div className={"cliente-list padding-cliente-list"}>     
                        <React.Fragment>
                            <div className="product-list">
                                <div className="item" >
                                    <Filtros handleRegional={handleRegional} handleAgencia={handleAgencia} handleZona={handleZona} 
                                        handleTipoSucursal={handleTipoSucursal} handleFiltros={handleFiltros} handleFiltroUsuario={handleFiltroUsuario} 
                                        handleFechaInicial={handleFechaInicial} handleFechaFinal={handleFechaFinal}
                                        habilitarFecha={false} habilitarFechaInicial={true} habilitarFechaFinal={true} habilitarTipo={true} 
                                        habilitarRegional={true} habilitarAgencia={true} habilitarZona={true} habilitarUsuario={true} />
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </div>

                <div style={{display:"block", width:"100%"}}>
                    <EstadisticasAgendamiento fechaInicial={fechaInicial} fechaFinal={fechaFinal} regional={regional} agencia={agencia} zona={zona} 
                        filtroUsuario={filtroUsuario} />
                    {tipoUsuario !== "us_sys" ? (
                        <Cliente search={search} agencia={agencia} zona={zona} tiposucursal={tipoSucursal} filtroUsuario={filtroUsuario} 
                            padding={false} interfaz={"table"} />
                    ) : null }
                </div>
            </div>

            <TabbarInicial item={2}/>
            
        </React.Fragment>
    )
}

export default withRouter(ListadoClientes);