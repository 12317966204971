import React from 'react';
import { withRouter } from "react-router-dom";
import close from '../../assets/icon/close_grey.svg';

import * as Constants from '../../constants/global';

import axios from 'axios';

const RecuperacionClaveModal = (props) => {

    const [usuario, setUsuario] = React.useState(''); 
    const [errorCorreo, setErrorCorreo] = React.useState("");

    const handleClose = () => {
        document.querySelector(".modal-recuperacion").classList.remove("show-modal");
    }

    const handleRecuperar = () => {

        if(usuario == "") {
            setErrorCorreo("Debe Ingresar un usuario");
        } else {
            setErrorCorreo("");

            let url = Constants.URL_BASE + Constants.WSRECUPERACIONCLAVEUSUARIO;

            let data = {
                "Usuario" : usuario
            };
    
            axios.post(url, data)
            .then(res => {
    
                if(res.data.Recuperacion == "1") {

                    alert("Su clave fue enviada al correo electronico ingresado"); 
                    document.querySelector(".modal-recuperacion").classList.remove("show-modal");
    
                } else {
                    alert("El usuario ingresado no cuenta con un correo registrado")
                }
            });
        }
    }

    return (
        <div className="modal modal-recuperacion">
            <div className="modal-content">       
                <div className="title">
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                    <p>Ingrese el usuario registrado</p>
                </div>
                <div className="content sm default">
                    <input 
                        type="number"
                        placeholder="Usuario"
                        onChange={e => setUsuario(e.target.value)}
                        value={usuario}>
                    </input>
                    { errorCorreo.length > 0 ? (
                        <span className="error">
                            <p>{errorCorreo}</p>
                        </span>
                    ) : null }
                </div>
                
                <div className="footer">
                    <button type="submit" className="btn-large" onClick={() => handleRecuperar()}>Solicitar Clave</button>
                </div>
            </div>

        </div>
        
        
    )
}

export default withRouter(RecuperacionClaveModal);