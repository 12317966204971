import { DATA_PRODUCTS } from "../constants/global";

const INITIAL_STATE = {
  total: null,
  pages: null,
  next: null,
  prev: null,
  now: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DATA_PRODUCTS:
      return {
        ...state,
        total: action.payload.total,
        pages: action.payload.pages,
        next: action.payload.next,
        prev: action.payload.prev,
        now: action.payload.now,
      };
    default:
      return state;
  }
};
