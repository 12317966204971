import React, { useState, useEffect } from "react";

import Filtros from "../navbar/Filtros";
import Cliente from "./Cliente";
import Inventario from "./Inventario";

import NavbarInicial from "../navbar/NavbarInicial";

import { ReactComponent as Search } from "../../assets/icon/search.svg";

import { withRouter } from "react-router-dom";
import TabbarInicial from "../navbar/TabbarInicial";

const InformeInventario = () => {
  return (
    <React.Fragment>
      <div className="navbar">
        <div className="navbar-header">
          <NavbarInicial item={9} />
          {/* <div className="input-search">
                        <input 
                            type="text" 
                            placeholder="Buscar cliente..."
                            name="search"
                            id="search"
                            onKeyPress={handleKeyPress}
                            >
                        </input>
                        <div className="button-Search">
                            <button onClick={() => handleBuscarCliente()}><Search className="svg-una-capa" style={{width:"25px", height:"25px"}}/></button>
                        </div>
                    </div> */}
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {/* <div className="main">
          <div className={"cliente-list padding-cliente-list"}>
            <React.Fragment>
              <div className="product-list">
                <div className="item">
                  <Filtros
                    handleRegional={handleRegional}
                    handleAgencia={handleAgencia}
                    handleZona={handleZona}
                    handleTipoSucursal={handleTipoSucursal}
                    handleFiltros={handleFiltros}
                    handleFiltroUsuario={handleFiltroUsuario}
                    handleFechaInicial={handleFechaInicial}
                    handleFechaFinal={handleFechaFinal}
                    habilitarFecha={false}
                    habilitarFechaInicial={true}
                    habilitarFechaFinal={true}
                    habilitarTipo={true}
                    habilitarRegional={true}
                    habilitarAgencia={true}
                    habilitarZona={true}
                    habilitarUsuario={true}
                  />
                </div>
              </div>
            </React.Fragment>
          </div>
        </div> */}

        <div style={{ display: "block", width: "100%" }}>
          <Inventario />
          {/* <Cliente search={search} agencia={agencia} zona={zona} tiposucursal={tipoSucursal} filtroUsuario={filtroUsuario} padding={false} interfaz={"table"} /> */}
        </div>
      </div>

      <TabbarInicial item={2} />
    </React.Fragment>
  );
};

export default withRouter(InformeInventario);
