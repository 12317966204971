import { CUSTOMER_PROFILE } from "../constants/global";

const INITIAL_STATE = {
  codigo: null,
  sucursal: null,
  direccion: null,
  telefono: null,
  celular: null,
  correo: null,
  listaPrecio: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CUSTOMER_PROFILE:
      return {
        ...state,
        codigo: action.payload[0].codigo,
        sucursal: action.payload[0].sucursal,
        direccion: action.payload[0].direccion,
        telefono: action.payload[0].telefono,
        celular: action.payload[0].celular,
        correo: action.payload[0].correo,
        listaPrecio: action.payload[0].listaPrecio,
      };
    default:
      return state;
  }
};
