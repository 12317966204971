import React, { useState, useEffect } from 'react';

import axios from 'axios';

import * as Constants from '../../constants/global';
import toast from "toasted-notes";

import Progress from "../util/Progress";


// import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';


const SeguimientoProducto = (props) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = () => {

        let dataExcel = [];
        let fileName = "Seguimiento Producto";

        Object(estadisticasxproducto).map((item, i) => {
            dataExcel.push({
                "Cod. Producto" : item.codProducto,
                "Nombre" : item.nombre,
                "Unidades Sugeridas" : item.sugerido,
                "Unidades Venta" : item.unidades,
                "Valor Bruto" : Constants.copFormat(item.ValorB),
                "Descuento" : Constants.copFormat(item.descuento),
                "Valor Neto" : Constants.copFormat(item.ValorN),
                "% Cumplimiento" : "% " + parseFloat(item.cumplimiento).toFixed(2)
            });
        });


        const ws = XLSX.utils.json_to_sheet(dataExcel);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    let usuario = localStorage.getItem("usuario");
    const [estadisticasxproducto, setEstadisticasxProducto] = useState();

    const [loader, setLoader] = React.useState(false);

    const loadDatos = () => {

        setLoader(true)

        let url = Constants.URL_BASE + Constants.WSESTADISTICASVENTASXPRODUCTO + 
        "?usuario=" + usuario +
        "&fechaInicial=" + (props.fechaInicial ? props.fechaInicial : Constants.getDate(0,"-")) +
        "&fechaFinal=" + (props.fechaFinal ? props.fechaFinal : Constants.getDate(0,"-"))
        
        if(props.filtroUsuario){
            url += "&filtroUsuario=" + props.filtroUsuario;
        }
        if(props.regional){
            url += "&regional=" + props.regional;
        }
        if(props.agencia){
            url += "&agencia=" + props.agencia;
        }
        if(props.zona){
            url += "&zona=" + props.zona;
        }
  
        axios.get(url)
        .then((res) => {
            setEstadisticasxProducto(res.data);
            setLoader(false)
        })
        .catch((err) => {
          toast.notify("Error al cargar los datos de estadisticas");
        });
    }

    useEffect(() => {
        if(props.zona && props.filtroUsuario){
            loadDatos();
        }
    }, [props.fechaInicial, props.fechaFinal, props.filtroUsuario, props.regional, props.agencia, props.zona])

    return (
        <div className="main">
            <div className='cliente-list'>
                <div className="product-list">
                    {loader ? <Progress color={"#007daf"} /> : (
                        <React.Fragment>
                            {estadisticasxproducto ? (
                                <React.Fragment>
                                    <div className='item' style={{display:"block"}}>
                                        <div className='exportar' style={{display:"block"}}>
                                            <button className="btn-exportar" onClick={(e) => exportToExcel()}>Exportar</button>
                                        </div>
                                        <table style={{marginTop:"20px"}} className="table-estadisticas-agendamiento">
                                            <thead>
                                                <tr>
                                                    <th>Cod. Producto</th>
                                                    <th>Nombre</th>
                                                    <th>Unidades Sugeridas</th>
                                                    <th>Unidades Venta</th>
                                                    <th>Valor Bruto</th>
                                                    <th>Descuento</th>
                                                    <th>Valor Neto</th>
                                                    <th>% Cumplimiento</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {estadisticasxproducto.map((item, i) => (
                                                    <tr>
                                                        <td>{item.codProducto}</td>
                                                        <td>{item.nombre}</td>
                                                        <td>{item.sugerido}</td>
                                                        <td>{item.unidades}</td>
                                                        <td>{Constants.copFormat(item.ValorB)}</td>
                                                        <td>{Constants.copFormat(item.descuento)}</td>
                                                        <td>{Constants.copFormat(item.ValorN)}</td>
                                                        <td>% {parseFloat(item.cumplimiento).toFixed(2)}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </React.Fragment>
                            ) :null }
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    )
}


export default SeguimientoProducto;