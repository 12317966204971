import { combineReducers } from "redux";
import quantityClients from "./quantityClientsReducer";
import products from "./productReducer";
import quantityProducts from "./quantityProductsReducer";
import customerProfile from "./customerProfileReducer";
import productAll from "./productAllReducer";
import discountsReducer from "./discountsReducer";

export default combineReducers({
  clientesAgendados: quantityClients,
  productos: products,
  quantityProducts: quantityProducts,
  perfilCliente: customerProfile,
  productosTodos: productAll,
  descuentos: discountsReducer,
});
