import React, { useState, useEffect } from "react";

import { withRouter } from "react-router-dom";
import * as Constants from "../../constants/global";
import axios from "axios";

import Progress from "../util/Progress";
import noimage from "../../assets/img/noimage.png";

import AgendamientoModal from "../modal/AgendamientoModal";
import { formatDate } from "../util/date";

const Encabezado = ({ search }) => {
  const [loader, setLoader] = React.useState(false);
  const [bakup,setBakup] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [details, setDetails] = useState(null);
  const [detail, setDetail] = useState(null);
  const [detailsProduct, setDetailsProduct] = useState(null);
  const [clientSelected, setClientSelected] = useState(null);

  const [sucursal, setSucursal] = useState("");
  const [codCliente, setCodCliente] = useState("");
  const [esSucursal, setEsSucursal] = useState("");
  const [regional, setRegional] = useState("");

  let usuario = localStorage.getItem("usuario");
  let tipoUsuario = localStorage.getItem("tipoUsuario");

  const load = async () => {
    let url = `${
      Constants.URL_BASE + Constants.WSHISTORICO
    }?usuario=${usuario}&tipoUsuario=${tipoUsuario}`;

    axios
      .get(url)
      .then((res) => {
        setLoader(false);
        let array = res.data;
        setBakup(array);
        setClientes(array);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cargarMas = () => {
    setLoader(true);
    load();
  };

  const handleDetail = (item) => {
    setDetail(item);
    let url = `${
      Constants.URL_BASE + Constants.WSDETAIL
    }?usuario=${usuario}&tipoUsuario=${tipoUsuario}&documento=${item.documento}`;

    axios
      .get(url)
      .then((res) => {
        setLoader(false);
        let array = res.data;
        setDetails(array);
        setClientSelected(item);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDetailProduct = (item) => {
    let url = `${
      Constants.URL_BASE + Constants.WSDETAILPRODUCT
    }?usuario=${usuario}&tipoUsuario=${tipoUsuario}&documento=${
      detail.documento
    }&producto=${item.producto}`;

    axios
      .get(url)
      .then((res) => {
        setLoader(false);
        let array = res.data;
        setDetailsProduct(array);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filter = (text) => {
    const newData = bakup.filter(function (item) {
      const campo = item.razonSocial.toUpperCase();
      const textData = text.toUpperCase();
      return campo.indexOf(textData) > -1;
    });
    setClientes(newData);
    setLoader(false)
  };

  useEffect(() => {
    setLoader(true);

    if (search != "") {
      filter(search);
    } else {
      load();
    }
  }, [search]);

  // VISTA
  return (
    <div className="main">
      <div className="encabezado-list">
        <React.Fragment>
          {clientes && !details && (
            <React.Fragment>
              <div className="product-list">
                {clientes.map((item, i) => {
                  return (
                    <div className="item" key={i}>
                      <div className="start">
                        <div className={"img-cliente"}>
                          <img
                            width="250"
                            className="img-product"
                            src={noimage}
                          />
                        </div>
                        <div>
                          <p className="nombre">
                            <b>Nombre:</b> {item.razonSocial}
                          </p>
                          <p className="codigo">
                            <b>Código:</b> {item.codigo}
                          </p>
                          <p className="sucursal">
                            <b>Sucursal:</b> {item.sucursal}
                          </p>
                          <p className="dirección">
                            <b>Venta:</b>{" "}
                            {"$ " +
                              new Intl.NumberFormat("en", {
                                numberingSystem: "latn",
                                style: "decimal",
                                currency: "COP",
                              }).format(item.valorventa)}
                          </p>
                          {/* <p className="razon-social"><b>Razón Social:</b> {item.razonsocial}</p>
                                                  <p className="ciudad"><b>Ciudad:</b> {item.ciudad}</p> */}
                        </div>
                      </div>
                      <div className="end">
                        <div className="action">
                          <button
                            style={{ cursor: "pointer" }}
                            className="btn-agendar"
                            onClick={() => handleDetail(item)}
                          >
                            Ver Detalles
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {loader ? <Progress color={"#007daf"} /> : null}

              <div className="load-mas">
                {clientes.now < clientes.next ? (
                  <button onClick={() => cargarMas()}>Cargar más</button>
                ) : null}
              </div>
            </React.Fragment>
          )}
          {details && !detailsProduct && (
            <React.Fragment>

              <div className="product-list">
                <div className="item">
                  <button
                    style={{
                      // margin: "1rem 0px",
                      backgroundColor: "#009ae2",
                      color: "white",
                      borderRadius: "8px",
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      cursor: "pointer",
                      width: "80px",
                      height: "40px",
                    }}
                    className="btn-agendar"
                    onClick={() => {
                      setDetails(null);
                      load();
                    }}
                  >
                    Volver
                  </button>
                  { clientSelected != null ? (
                    <div>
                      <p className="nombre">
                        <b>Nombre:</b> {clientSelected.razonSocial}
                      </p>
                      <p className="codigo">
                        <b>Código:</b> {clientSelected.codigo}
                      </p>
                      <p className="sucursal">
                        <b>Sucursal:</b> {clientSelected.sucursal}
                      </p>
                      <p className="dirección">
                          <b>Venta:</b>{" "}
                          {"$ " +
                            new Intl.NumberFormat("en", {
                              numberingSystem: "latn",
                              style: "decimal",
                              currency: "COP",
                            }).format(clientSelected.valorventa)}
                        </p>
                    </div>
                  ) : null }
                </div>
              </div>

              <div className="product-list">
                {details.map((item, i) => {
                  return (
                    <div className="item" key={i}>
                      <div className="start">
                        <div className={"img-cliente"}>
                          <img
                            width="250"
                            className="img-product"
                            src={noimage}
                          />
                        </div>
                        <div>
                          <p className="codigo">
                            <b>Producto:</b> {item.producto}
                          </p>
                          <p className="nombre">
                            <b>Nombre:</b> {item.Nombre}
                          </p>
                          <p className="codigo">
                            <b>Cantidad:</b> {item.cantidad}
                          </p>
                          <p className="sucursal">
                            <b>Entregado:</b> {item.entregado}
                          </p>

                          <p className="sucursal">
                            <b>Pendiente:</b> {item.pendiente}
                          </p>
                        </div>
                      </div>
                      <div className="end">
                        <div className="action">
                          <button
                            style={{ cursor: "pointer" }}
                            className="btn-agendar"
                            onClick={() => handleDetailProduct(item)}
                          >
                            Ver Detalles
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              {loader ? <Progress color={"#007daf"} /> : null}

              <div className="load-mas">
                {clientes.now < clientes.next ? (
                  <button onClick={() => cargarMas()}>Cargar más</button>
                ) : null}
              </div>
            </React.Fragment>
          )}
          {detailsProduct && (
            <React.Fragment>
              <div className="product-list">
                <div className="item">
                  <button
                    style={{
                      margin: "1rem 0px",
                      backgroundColor: "#009ae2",
                      color: "white",
                      borderRadius: "8px",
                      fontSize: "0.8rem",
                      fontWeight: "bold",
                      cursor: "pointer",
                      width: "80px",
                      height: "40px",
                    }}
                    className="btn-agendar"
                    onClick={() => {
                      setDetailsProduct(null);
                    }}
                  >
                    Volver
                  </button>
                  { clientSelected != null ? (
                    <div>
                      <p className="nombre">
                        <b>Nombre:</b> {clientSelected.razonSocial}
                      </p>
                      <p className="codigo">
                        <b>Código:</b> {clientSelected.codigo}
                      </p>
                      <p className="sucursal">
                        <b>Sucursal:</b> {clientSelected.sucursal}
                      </p>
                      <p className="dirección">
                          <b>Venta:</b>{" "}
                          {"$ " +
                            new Intl.NumberFormat("en", {
                              numberingSystem: "latn",
                              style: "decimal",
                              currency: "COP",
                            }).format(clientSelected.valorventa)}
                        </p>
                    </div>
                  ) : null }
                </div>
              </div>
              
              <div className="product-list">
                {detailsProduct.map((item, i) => {
                  return (
                    <div className="item" key={i}>
                      <div className="start">
                        <div className={"img-cliente"}>
                          <img
                            width="250"
                            className="img-product"
                            src={noimage}
                          />
                        </div>
                        <div>
                          <p className="codigo">
                            <b>Tipo:</b> {item.tipoPedido}
                          </p>
                          <p className="codigo">
                            <b>Producto:</b> {item.producto}
                          </p>
                          <p className="nombre">
                            <b>Nombre:</b> {item.Nombre}
                          </p>
                          <p className="codigo">
                            <b>Cantidad:</b> {item.cantidad}
                          </p>
                          <p className="dirección">
                            <b>Venta:</b>{" "}
                            {"$ " +
                              new Intl.NumberFormat("en", {
                                numberingSystem: "latn",
                                style: "decimal",
                                currency: "COP",
                              }).format(item.vrvta)}
                          </p>
                          <p className="dirección">
                            <b>Fecha:</b> {item.fecha}
                          </p>
                        </div>
                      </div>
                      {/* <div className="action"> */}
                        {/* <button
                          style={{ cursor: "pointer" }}
                          className="btn-agendar"
                          onClick={() => handleDetailProduct(item)}
                        >
                          Ver Detalles
                        </button> */}
                      {/* </div> */}
                    </div>
                  );
                })}
              </div>

              {loader ? <Progress color={"#007daf"} /> : null}

              <div className="load-mas">
                {clientes.now < clientes.next ? (
                  <button onClick={() => cargarMas()}>Cargar más</button>
                ) : null}
              </div>
            </React.Fragment>
          )}
          <AgendamientoModal
            sucursal={sucursal}
            codCliente={codCliente}
            essucursal={esSucursal}
            regional={regional}
          />
        </React.Fragment>
      </div>
    </div>
  );
};

export default withRouter(Encabezado);
