import React, { useState, useEffect } from 'react';

import Filtros from '../navbar/Filtros';
import Cliente from './Cliente';

import NavbarInicial from '../navbar/NavbarInicial';

import {ReactComponent as Search} from '../../assets/icon/search.svg';

import { withRouter } from 'react-router-dom';
import TabbarInicial from '../navbar/TabbarInicial';

const ListadoClientes = () => {

    const [search, setSearch] = useState();
    const [tipoSucursal, setTipoSucursal] = useState();
    const [agencia, setAgencia] = useState();
    const [zona, setZona] = useState();

    const handleTipoSucursal = item => {
        setTipoSucursal(item);
    }
    
    const handleAgencia = item => {
        setAgencia(item);
    }

    const handleZona = item => {
        setZona(item);
    }

    const handleFiltros = (tipoSucursal, agencia, zona ) => {
        setTipoSucursal(tipoSucursal)
        setAgencia(agencia);
        setZona(zona);
    }

    const handleKeyPress = (e) => {
        if(e.key === "Enter"){
            handleBuscarCliente()
        }
    }

    const handleBuscarCliente = () => {
        let busqueda = document.getElementById("search").value
        setSearch(busqueda)
    }

    useEffect(() => {
        let sucursal = localStorage.getItem("sucursal");
        localStorage.removeItem(sucursal);
    }) 

    return (
        <React.Fragment>
            <div className="navbar">
                <div className="navbar-header">

                    <NavbarInicial item={2}/>
                    
                    <Filtros handleAgencia={handleAgencia} handleZona={handleZona} handleTipoSucursal={handleTipoSucursal} handleFiltros={handleFiltros} 
                        habilitarFecha={false} habilitarTipo={true} habilitarAgencia={true} habilitarZona={true} habilitarUsuario={false} />
                    
                    <div className="input-search">
                        <input 
                            type="text" 
                            placeholder="Buscar cliente..."
                            name="search"
                            id="search"
                            onKeyPress={handleKeyPress}
                            // onChange={(e) => setSearch(e.target.value)}
                            // value={search}>
                            >
                        </input>
                        <div className="button-Search">
                            <button onClick={() => handleBuscarCliente()}><Search className="svg-una-capa" style={{width:"25px", height:"25px"}}/></button>
                        </div>
                    </div>
                    
                </div>
            </div>

            <Cliente search={search} agencia={agencia} zona={zona} tiposucursal={tipoSucursal} filtroUsuario={"-1"} padding={true} interfaz={"lista"} />

            <TabbarInicial item={2}/>
            
        </React.Fragment>
    )
}

export default withRouter(ListadoClientes);