import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import axios from 'axios';
import Help from '../../assets/img/help.png';


import * as Constants from '../../constants/global';

const Ayuda = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));
    let sucursal = localStorage.getItem('sucursal');
    let codCliente = localStorage.getItem('codCliente');

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();

    const [nombreVendedor, setNombreVendedor] = useState();
    const [telefonoVendedor, setTelefonoVendedor] = useState();
    const [telefonoServicioCliente, setTelefonoServicioCliente] = useState();
    

    const loadInfoAyuda = async () => {

        setNombreVendedor(empresa.sucursal.NombreVendedor ? empresa.sucursal.NombreVendedor : "")
        setTelefonoVendedor(empresa.sucursal.TelefonoVendedor ? empresa.sucursal.TelefonoVendedor : "")
        setTelefonoServicioCliente(empresa.sucursal.TelefonoServicioAlCliente ? empresa.sucursal.TelefonoServicioAlCliente : "")

    }
    
    const getCantidad = () => {

        Constants.calcularDescuento()
        let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;
        let cantidad = 0;

        Object.keys(carrito).map((item, i) => {

            cantidad += carrito[item]["cantidad"];

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = (carrito[item]["descuentoAplicado"] ? carrito[item]["descuentoAplicado"] : 0);
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        setCantidad(cantidad)

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    useEffect(() => {
        loadInfoAyuda();
        getCantidad();
    })

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={10} cantidad={cantidad} totalNavbar={totalNavbar} />
            </div>

            <div className="pedido-list sugerido">
                <div className="seccion-ayuda">
                    <div className="logo-ayuda">
                        <img src={Help} width="40" height="40"></img>
                    </div>
                    <div className="start">
                        <a>Manual de usuario</a>
                        <br/>
                    </div>
                </div>
            </div>

            <Tabbar item={10}/>
            
        </React.Fragment>
    )
}

export default Ayuda;