import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle, css } from "styled-components";

import NavbarInicial from "../navbar/NavbarInicial";
import Tabbar from "../navbar/Tabbar";
import Producto from "./Producto";

import * as Constants from "../../constants/global";

import { ReactComponent as Search } from "../../assets/icon/search.svg";

import axios from "axios";

import cuadricula from "../../assets/img/cuadricula.png";
import { withRouter } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import toast from "toasted-notes";

const GlobalStyle = createGlobalStyle`

`;

const SharedStyles = css`
  background-color: #eee;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin: 10px 0 20px 0;
  padding: 20px;
  box-sizing: border-box;
`;

const StyledFormWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
  padding: 0 20px;
  margin-top: 1rem;
`;

const StyledForm = styled.form`
  width: 100%;
  max-width: 700px;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
`;

const StyledInput = styled.input`
  display: block;
  width: 100%;
  ${SharedStyles}
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyleButton = styled.button`
  display: block;
  background-color: #009ae2;
  color: #fff;
  font-size: 1rem;
  border: 0;
  border-radius: 5px;
  height: 40px;
  padding: 0 20px;
  cursor: pointer;
  box-sizing: border-box;
`;

const StyledError = styled.div`
  color: red;
  font-weight: 800;
  margin: 0 0 40px 0;
`;

const Perfil = () => {
  let codCliente = localStorage.getItem("codCliente");

  const [state, setState] = useState("");
  const [error, setError] = useState("");

  const [totalNavbar, setTotalNavbar] = useState();
  const [cantidad, setCantidad] = useState();
  const [sucursalesVenta, setSucursalesVenta] = useState({});

  const getCantidad = () => {
    let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

    let iva = 0;
    let total = 0;
    let descuentoTotal = 0;
    let cantidad = 0;

    Object.keys(carrito).map((item, i) => {
      cantidad += carrito[item]["cantidad"];

      let subtotal = carrito[item]["cantidad"] * carrito[item]["precio"];
      let descuento = carrito[item]["descuentoAplicado"]
        ? carrito[item]["descuentoAplicado"]
        : 0;
      iva +=
        (subtotal - descuento) *
        (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0);

      descuentoTotal += descuento;
    });

    setCantidad(cantidad);

    Object.keys(carrito).map((item, i) => {
      total += carrito[item]["cantidad"] * carrito[item]["precio"];
    });

    setTotalNavbar(total - descuentoTotal + iva);
  };

  const loadSucursales = () => {
    let url =
      Constants.URL_BASE +
      Constants.WSGETSUCURSALESCLIENTE +
      "?codCliente=" +
      codCliente;

    axios.get(url).then((res) => {
      setSucursalesVenta(res.data);
      // celular: ""
      // codigo: "0010000320"
      // correo: ""
      // direccion: "CL 42A SUR 80F 27"
      // sucursal: "0060035053"
      // telefono: "5700023"
      const initalState = {
        codigo: res.data[0].codigo,
        sucursal: res.data[0].sucursal,
        direccion: res.data[0].direccion,
        telefono: res.data[0].telefono,
        celular: res.data[0].celular,
        correo: res.data[0].correo,
        listaPrecio: res.data[0].listaPrecio
      };
      setState(initalState);
    });
  };

  const handleSucursal = (sucursal) => {
    const s = sucursalesVenta.find((s) => s.sucursal == sucursal);
    const initalState = {
      codigo: s.codigo,
      sucursal: s.sucursal,
      direccion: s.direccion,
      telefono: s.telefono,
      celular: s.celular,
      correo: s.correo,
      listaPrecio: s.listaPrecio,
    };
    setState(initalState);
  };

  const handleInput = (e) => {
    const inputName = e.currentTarget.name;
    const value = e.currentTarget.value;
    setState((prev) => ({ ...prev, [inputName]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (state.direccion === "") {
      setError(`La direccion es obligatoria`);
      return;
    }

    setError("");
    // const regex = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    // const test = regex.test(state.email);
    // console.log(test);

    let url = Constants.URL_BASE + Constants.WSUPDATECLIENTE;
    axios
      .post(url, state)
      .then((res) => {
        toast.notify("Cliente Actualizado Correctamente");
        loadSucursales();
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getCantidad();
    loadSucursales();
  }, []);

  return (
    <React.Fragment>
      <div className="navbar principal">
        <Navbar item={2} cantidad={cantidad} totalNavbar={totalNavbar} />
      </div>
      <div className="pedido-list sugerido">
        <div className="seccion-ayuda">
          <h3>Sucursal</h3>
          <select
            className={"select-css"}
            onChange={(e) => handleSucursal(e.target.value)}
          >
            {sucursalesVenta.length > 0
              ? sucursalesVenta.map((item, i) => (
                  <option value={item.sucursal} key={item.sucursal}>
                    {item.sucursal}
                  </option>
                ))
              : null}
          </select>
        </div>
        <br />
        <StyledFormWrapper>
          <StyledForm onSubmit={handleSubmit}>
            <label htmlFor="codigo">Codigo</label>
            <StyledInput
              type="text"
              name="codigo"
              value={state.codigo}
              onChange={handleInput}
              readOnly={true}
            />
            <label htmlFor="sucursal">Sucursal </label>
            <StyledInput
              type="text"
              name="sucursal"
              value={state.sucursal}
              onChange={handleInput}
              readOnly={true}
            />
            <label htmlFor="direccion">Dirección</label>
            <StyledInput
              type="text"
              name="direccion"
              value={state.direccion}
              onChange={handleInput}
            />
            <label htmlFor="telefono">Telefono</label>
            <StyledInput
              type="text"
              name="telefono"
              value={state.telefono}
              onChange={handleInput}
            />
            <label htmlFor="celular">Celular</label>
            <StyledInput
              type="text"
              name="celular"
              value={state.celular}
              onChange={handleInput}
            />
            <label htmlFor="correo">Correo</label>
            <StyledInput
              type="email"
              name="correo"
              value={state.correo}
              onChange={handleInput}
            />
            <label htmlFor="listaPrecio">Lista Precio</label>
            <StyledInput
              type="text"
              name="listaPrecio"
              value={state.listaPrecio}
              onChange={handleInput}
              readOnly={true}
            />
            {error && (
              <StyledError>
                <p>{error}</p>
              </StyledError>
            )}
            <StyledButtons>
              <StyleButton type="submit">Modificar</StyleButton>
            </StyledButtons>
          </StyledForm>
        </StyledFormWrapper>
      </div>

      <Tabbar item={2} />
    </React.Fragment>
  );
};

export default withRouter(Perfil);
