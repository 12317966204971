import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import axios from 'axios';

import * as Constants from '../../constants/global';

const Cartera = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));
    let sucursal = localStorage.getItem('sucursal');
    let codCliente = localStorage.getItem('codCliente');

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();
    // const [cartera, setCartera] = useState();

    // const loadCartera = async () => {
    //     let url = Constants.URL_BASE +
    //                 Constants.WSCARTERACLIENTE +
    //                 "?origen=" + empresa.codigo_empresa + 
    //                 "&cliente=" + empresa.sucursal.codigo;

    //     axios.get(url)
    //     .then(res => {
    //         setCartera(res.data)
    //     });
    // }
    
    const getCantidad = () => {

        Constants.calcularDescuento()
        let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;
        let cantidad = 0;

        Object.keys(carrito).map((item, i) => {

            cantidad += carrito[item]["cantidad"];

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = (carrito[item]["descuentoAplicado"] ? carrito[item]["descuentoAplicado"] : 0);
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        setCantidad(cantidad)

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    useEffect(() => {
        // loadCartera();
        getCantidad();
    })

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={8} cantidad={cantidad} totalNavbar={totalNavbar} />
            </div>
            <div className="pedido-list sugerido">
                <div className="seccion-ayuda">
                    <div className="start">
                    <p><b>CUPO: </b> {Constants.copFormat(empresa.sucursal.cupo)}
                    {/* { new Intl.NumberFormat("es-CO", {
                        style: "currency",
                        currency: "COP"
                        }).format(empresa.sucursal.cupo)
                    } */}
                    </p><br/>
                    <p><b>CUPO USADO: </b> {Constants.copFormat(empresa.sucursal.cupoUsado)}
                    {/* { new Intl.NumberFormat("es-CO", {
                        style: "currency",
                        currency: "COP"
                        }).format(empresa.sucursal.cupoUsado)
                    } */}
                    </p><br/>
                    <p><b>CUPO COMPROMETIDO: </b> {Constants.copFormat(empresa.sucursal.cupoComprometido)}
                    {/* { new Intl.NumberFormat("es-CO", {
                        style: "currency",
                        currency: "COP"
                        }).format(empresa.sucursal.cupoComprometido)
                    } */}
                    </p><br/>
                    <p><b>CUPO DISPONIBLE: </b> {Constants.copFormat(empresa.sucursal.cupoDisponible)}
                    {/* { new Intl.NumberFormat("es-CO", {
                        style: "currency",
                        currency: "COP"
                        }).format(empresa.sucursal.cupoDisponible)
                    } */}
                    </p><br/>
                    </div>
                </div>
            </div>
            <Tabbar item={8}/>
        </React.Fragment>
    )
}


export default Cartera;