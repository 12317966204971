import React, { useState, useEffect } from "react";

import axios from "axios";

import * as Constants from "../../constants/global";
import toast from "toasted-notes";

import Progress from "../util/Progress";

// import Button from 'react-bootstrap/Button';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const Inventario = (props) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = () => {
    let dataExcel = [];
    let fileName = "Seguimiento Producto";

    Object(estadisticasxproducto).map((item, i) => {
      dataExcel.push({
        Codigo: item.codigo,
        Nombre: item.Nombre,
        Cantidad: item.cantidad,
        Venta: item.venta,
        // "Saldo": Constants.copFormat(item.Saldo),
        Saldo: item.saldo,
      });
    });

    const ws = XLSX.utils.json_to_sheet(dataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  let usuario = localStorage.getItem("usuario");
  const [estadisticasxproducto, setEstadisticasxProducto] = useState();

  const [loader, setLoader] = React.useState(false);

  const loadDatos = () => {
    setLoader(true);

    let url =
      Constants.URL_BASE +
      Constants.WSINFORMEINVENTARIOXPRODUCTO +
      "?usuario=" +
      usuario;

    axios
      .get(url)
      .then((res) => {
        setEstadisticasxProducto(res.data);
        setLoader(false);
      })
      .catch((err) => {
        toast.notify("Error al cargar los datos de estadisticas");
      });

  };

  useEffect(() => {
    loadDatos();
  }, []);

  return (
    <div className="main" style={{ marginTop: "6%" }}>
      <div className="cliente-list">
        <div className="product-list">
          {loader ? (
            <Progress color={"#007daf"} />
          ) : (
            <React.Fragment>
              {estadisticasxproducto ? (
                <React.Fragment>
                  <div className="item" style={{ display: "block" }}>
                    <div className="exportar" style={{ display: "block" }}>
                      <button
                        className="btn-exportar"
                        onClick={(e) => exportToExcel()}
                      >
                        Exportar
                      </button>
                    </div>
                    <table
                      style={{ marginTop: "20px" }}
                      className="table-estadisticas-agendamiento"
                    >
                      <thead>
                        <tr>
                          <th>Codigo</th>
                          <th>Nombre</th>
                          <th>Cantidad</th>
                          <th>Unidades Venta</th>
                          <th>Saldo</th>
                        </tr>
                      </thead>
                      <tbody>
                        {estadisticasxproducto.map((item, i) => (
                          <tr>
                            <td>{item.codigo}</td>
                            <td>{item.Nombre}</td>
                            <td>{item.cantidad}</td>
                            <td>{item.venta}</td>
                            <td>{item.saldo}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </React.Fragment>
              ) : null}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default Inventario;
