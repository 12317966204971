import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';

import axios from 'axios';

import close from '../../assets/icon/close_grey.svg';

const DistribucionVenta = (props) => {

    const [sucursalesVenta, setSucursalesVenta] = useState({});
    const [inventarioTotal, setInventarioTotal] = useState([]);

    const [cantidadDisponible, setCantidadDisponible] = useState(0);

    const loadSucursales = () => {

        let url = Constants.URL_BASE + Constants.WSGETSUCURSALESCLIENTE + "?codCliente=" + props.codCliente;
        
        axios.get(url)
        .then(res => {
            setSucursalesVenta(res.data);
        });
    }

    const handleClose = () => {
        document.querySelector(".modal-distribucion-venta").classList.remove("show-modal");
    }

    const handleCarritoSucursal_p = (cantidad, sucursal, item, event) => {

        const inventario = JSON.parse(localStorage.getItem("dataDistribucion")) || [];

        // if (localStorage.getItem("dataDistribucion") != null) {
        //     inventario.push(JSON.parse(localStorage.getItem("dataDistribucion")));
        // }

        cantidad = (cantidad == "" ? 0 : cantidad);

        if(cantidad >= 0) {

            if(inventario.findIndex(p => p.sucursal == sucursal ) == -1){
                inventario.push({
                    sucursal: sucursal,
                    productos: [],
                });
            }

            var indexSucursal = inventario.findIndex(p => p.sucursal==sucursal);

            if(indexSucursal != -1){

                if(inventario[indexSucursal].productos.findIndex(p => p.Codigo==props.codItemDistribucion) == -1){

                    if(cantidad <= cantidadDisponible) {
                        inventario[indexSucursal].productos.push({
                            Codigo: props.codItemDistribucion,
                            Precio: item.precio,
                            Iva: props.itemDistribucion.iva,
                            Cantidad: cantidad,
                            ValorDescuento: 0,
                            Param1: props.itemDistribucion.porcentajeDescuento,
                            Param2: parseInt(cantidad),
                            Param3: parseInt(props.itemDistribucion.codigoDescuento),
                            Param4: parseInt(props.itemDistribucion.clase),
                            DescripcionParam1: props.itemDistribucion.porcentajeDescuento ? "Porcentaje Descuento" : "",
                            DescripcionParam2: props.itemDistribucion.cantidadDescuento ? "Cantidad Descuento Aplicada" : "",
                            DescripcionParam3: props.itemDistribucion.codigoDescuento ? "Codigo Descuento" : "",
                            DescripcionParam4: props.itemDistribucion.clase ? "Clase" : ""
                        });
                    } else {
                        alert("Cantidad no valida");
                        event.preventDefault();
                    }

                } else {

                    var indexProducto = inventario[indexSucursal].productos.findIndex(p => p.Codigo==props.codItemDistribucion);

                    if(cantidad > 0) {

                        if(cantidad-inventario[indexSucursal].productos[indexProducto].Cantidad <= cantidadDisponible) {
                            inventario[indexSucursal].productos[indexProducto].Cantidad = cantidad;
                            inventario[indexSucursal].productos[indexProducto].Param2 = cantidad;
                        } else {
                            alert("Cantidad no valida");
                            event.preventDefault();
                        }
                    } else {
                        inventario[indexSucursal].productos.splice(indexProducto, 1);
                    }
                }

            } 

            setInventarioTotal(inventario);
            localStorage.setItem("dataDistribucion", JSON.stringify(inventario))

        } else {
            alert("Cantidad no valida");
            event.preventDefault();
        }
        
        getCantidadDisponible(inventario);
    }

    const getCantidadDisponible = (inventario) => {

        let cantidadOcupada = 0;

        inventario.map((item, i) => {

            if (item.productos.length > 0){

                var indexSucursal = inventario.findIndex(p => p.sucursal==item.sucursal);
                var indexProducto = inventario[indexSucursal].productos.findIndex(p => p.Codigo==props.codItemDistribucion);

                if(inventario[indexSucursal].productos[indexProducto]){
                    cantidadOcupada += parseInt(inventario[indexSucursal].productos[indexProducto].Cantidad);
                }

            }
        });
        
        if(props.itemDistribucion){
            let cantTotal = props.itemDistribucion.cantidad
            setCantidadDisponible(cantTotal - cantidadOcupada)
        }

    }

    useEffect(() => {

        setInventarioTotal(JSON.parse(localStorage.getItem("dataDistribucion")) || []);
        loadSucursales();

        getCantidadDisponible((JSON.parse(localStorage.getItem("dataDistribucion")) || []));

    }, [props.codcliente, props.itemDistribucion, props.codItemDistribucion])

    return (
        <div className="modal modal-distribucion-venta">
            <div className="modal-content">       
                <div className="title">
                    { props.itemDistribucion ?
                        <p>{props.itemDistribucion.nombre} </p> : null
                    }
                    <p style={{ color : "#e11223" }}>Cantidad a Ingresar: {cantidadDisponible} </p>

                    <hr/>
                    {/* <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>   */}
                </div>
                <div className="content full-content" >
                    <div className="list-items">
                        { sucursalesVenta.length > 0 ? sucursalesVenta.map((item, i) => (
                            <div className="item" style={{cursor:"default"}} key={i} >
                                <p><b>Sucursal: </b> {item.sucursal}</p>
                                <p><b>Direccion: </b> {item.direccion}</p>
                                    <div>
                                        <input 
                                            type="number"
                                            placeholder="Cantidad"
                                            onChange={e => handleCarritoSucursal_p(e.target.value, item.sucursal, props.itemDistribucion, e)}
                                            value = { ( inventarioTotal.findIndex(p => p.sucursal == item.sucursal ) != -1 ? (
                                                            inventarioTotal[inventarioTotal.findIndex(p => p.sucursal == item.sucursal )].productos.findIndex(p => p.Codigo==props.codItemDistribucion) != -1 ? 
                                                                inventarioTotal[inventarioTotal.findIndex(p => p.sucursal == item.sucursal )].productos[inventarioTotal[inventarioTotal.findIndex(p => p.sucursal == item.sucursal )].productos.findIndex(p => p.Codigo==props.codItemDistribucion)].Cantidad
                                                            : "" 
                                                        ) : "")
                                                    } 
                                        />
                                    </div>
                            </div>
                        )) : null }
                    </div>
                    <div className="footer">
                        <button type="submit" className="btn-large" onClick={() => handleClose()}>Aceptar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(DistribucionVenta);