import React, { useState, useEffect } from "react";
import Navbar from "../navbar/Navbar";
import Tabbar from "../navbar/Tabbar";
import Producto from "./Producto";
import { connect } from "react-redux";
import { traerPerfilCliente, cargarDescuentos } from "../../actions/index";
import * as Constants from "../../constants/global";

const Pedido = (props) => {
  let sucursal = localStorage.getItem("sucursal");
  let codCliente = localStorage.getItem("codCliente");
  let tipocredito = localStorage.getItem("tipocredito");
  let usuario = localStorage.getItem("usuario");

  const [search, setSearch] = useState();
  const [deboundcedSearch, setDeboundcedSearch] = useState();
  const [totalNavbar, setTotalNavbar] = useState();
  const [cantidad, setCantidad] = useState();

  const handleCantidad = () => {
    getCantidad();
  };

  const getCantidad = () => {
    Constants.calcularDescuento();
    let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

    let iva = 0;
    let total = 0;
    let descuentoTotal = 0;
    let cantidad = 0;

    Object.keys(carrito).map((item, i) => {
      cantidad += carrito[item]["cantidad"];

      let subtotal = carrito[item]["cantidad"] * carrito[item]["precio"];
      let descuento = carrito[item]["descuentoAplicado"]
        ? carrito[item]["descuentoAplicado"]
        : 0;
      iva +=
        (subtotal - descuento) *
        (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0);

      descuentoTotal += descuento;
    });

    setCantidad(cantidad);

    Object.keys(carrito).map((item, i) => {
      total += carrito[item]["cantidad"] * carrito[item]["precio"];
    });

    setTotalNavbar(total - descuentoTotal + iva);
  };

  useEffect(() => {
    getCantidad();
  }, []);

  useEffect(() => {
    let url =
      Constants.WSGETDATOSCLIENTE +
      "?codCliente=" +
      codCliente +
      "&sucursal=" +
      sucursal;
    props.traerPerfilCliente(url);

    url =
      Constants.URL_BASE +
      Constants.WSGRUPOSDESCUENTO +
      "?codCliente=" +
      codCliente;

    if (sucursal) {
      url += "&sucursal=" + sucursal;
    }

    if (tipocredito) {
      url += "&tipocredito=" + tipocredito;
    }

    if (usuario) {
      url += "&vendedor=" + usuario;
    }
    // Constants.cargarGruposDescuento(sucursal, codCliente, tipocredito, usuario);
    /*DOBLE TRABAJO AJUSTAR*/
    props.cargarDescuentos(url);
    // Constants.cargarListasDescuento(sucursal, codCliente, tipocredito);
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSearch(deboundcedSearch);
    }, 1200);
    return () => {
      clearTimeout(timerId);
    };
  }, [deboundcedSearch]);

  return (
    <React.Fragment>
      <div className="navbar">
        <div className="navbar-header">
          <Navbar item={1} cantidad={cantidad} totalNavbar={totalNavbar} />
          <div className="input-search">
            <input
              type="text"
              placeholder="Buscar producto Pedido..."
              name="search"
              id="search"
              onChange={(e) => setDeboundcedSearch(e.target.value)}
              value={deboundcedSearch}
            ></input>
          </div>
        </div>
      </div>

      <Producto tipo={2} search={search} handleCantidad={handleCantidad} />

      <Tabbar item={1} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    perfilCliente: Object.values(state.perfilCliente),
  };
};

export default connect(mapStateToProps, {
  traerPerfilCliente,
  cargarDescuentos,
})(Pedido);
