import React from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';

import close from '../../assets/icon/close_grey.svg';

const DetallePedidoModal = (props) => {

    const handleClose = () => {
        document.querySelector(".modal-sucursal").classList.remove("show-modal");
    }

    return (
        <div className="modal modal-sucursal">
            <div className="modal-content">       
                <div className="title">
                    <p>Detalle Factura</p>
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                </div>
                <div className="content full-content" >
                    <div className="list-items">
                        { props.detalle ? Object.keys(props.detalle).map((item, i) => (
                            <div className="item" style={{cursor:"default"}} key={i} >
                                <p><b>CÓDIGO DE PRODUCTO:</b> {props.detalle[item].codigo}</p>
                                <p><b>DESCRIPCIÓN:</b> {props.detalle[item].nombre_producto ? props.detalle[item].nombre_producto : props.detalle[item].nombre}</p>
                                <p><b>CANTIDAD:</b> {props.detalle[item].cantidad}</p>
                                <p><b>SUBTOTAL:</b> {Constants.copFormat(props.detalle[item].precio)}
                                {/* {new Intl.NumberFormat("es-CO", {
                                                    style: "currency",
                                                    currency: "COP"
                                            }).format(props.detalle[item].precio)} */}
                                </p>
                                <p><b>IVA:</b> {Constants.copFormat(props.detalle[item].iva)}
                                     {/* {new Intl.NumberFormat("es-CO", {
                                                         style: "currency",
                                                         currency: "COP"
                                                 }).format(props.detalle[item].iva)} */}
                                </p>
                                <p><b>TOTAL:</b> {Constants.copFormat(props.detalle[item].total)}
                                    {/* {new Intl.NumberFormat("es-CO", {
                                        style: "currency",
                                        currency: "COP"
                                    }).format(props.detalle[item].total)} */}
                                </p>
                            </div>
                        )) : null }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(DetallePedidoModal);