import _ from "lodash";
import { LOAD_PRODUCTS } from "../constants/global";
export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_PRODUCTS:
      return { ...state, lista: action.payload };
    default:
      return state;
  }
};
