import _ from "lodash";
import { LOAD_DISCOUNTS } from "../constants/global";
export default (state = {}, action) => {
  switch (action.type) {
    case LOAD_DISCOUNTS:
      return { state, ..._.mapKeys(action.payload, "codProducto") };
    default:
      return state;
  }
};
