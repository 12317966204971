import React, { useRef, Fragment, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { withRouter } from "react-router-dom";

import ObservacionesModal from '../modal/ObservacionesModal';

import logo_login from '../../assets/img/logo_interior.png';
import {ReactComponent as Back} from '../../assets/icon/back.svg';

// import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';

import * as Constants from '../../constants/global';

import noimage from '../../assets/img/noimage.png';

import error from '../../assets/icon/error.svg';

import DistribucionVentaModal from '../modal/DistribucionVentaModal';

import SignatureCanvas from "react-signature-canvas";

const DistribucionInventario = (props) => {

    const [carrito, setCarrito] = useState();

    const [total, setTotal] = useState(0);
    const [iva, setIva] = useState(0);
    const [maximoPedido, setMaximoPedido] = useState(false);
    const [descuento_total, setDescuento_total] = useState(0);

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();

    const [itemDistribucion, setItemDistribucion] = useState();
    const [codItemDistribucion, setCodItemDistribucion] = useState();

    const [estadoModal, setModalEstado] = useState(0);

    //Firma
    const signatureRef = useRef({});
    const [imageData, setImageData] = useState("");
    const [errorImage, setErrorImage] = useState(true);
    //Fin Firma

    const [correoEnvio, setCorreoEnvio] = React.useState(''); 

    let codCliente = localStorage.getItem('codCliente');
    let sucursal = localStorage.getItem('sucursal');
    let essucursal = localStorage.getItem('essucursal');
    let correo = localStorage.getItem('correo');

    let usuario = localStorage.getItem("usuario");

    const saveSignature = (signature) => {
        setImageData(signature);
        setErrorImage(false);
    }

    const getCantidad = () => {

        let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;
        let cantidad = 0;

        Object.keys(carrito).map((item, i) => {

            cantidad += carrito[item]["cantidad"];

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = (carrito[item]["descuentoAplicado"] ? carrito[item]["descuentoAplicado"] : 0);
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        setCantidad(cantidad)

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);

        return carrito
    }

    const getIva = () => {

        let descuento = Constants.calcularDescuento()

        setDescuento_total(descuento ? descuento : 0)

        let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

        let iva = 0;
        Object.keys(carrito).map((item, i) => {
            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = (carrito[item]["descuentoAplicado"] ? carrito[item]["descuentoAplicado"] : 0)
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );
        })
        setIva(iva);
    }

    const getTotal = () => {


        let carrito = JSON.parse(localStorage.getItem(codCliente)) || {};

        let total = 0;
        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotal(total);

    }

    const handleRegresar = () => {

        if(window.confirm("Si regresa tendrá que asignar inventario nuevamente.")){
            localStorage.removeItem("dataDistribucion")
            props.history.replace("/carrito", "urlhistory");
        }

    }

    const handleCancelarPedido = () => {
        Object.keys(carrito).map((item, i) => {
            delete carrito[item];
        });

        localStorage.setItem(codCliente, JSON.stringify(carrito));
            props.history.replace("/pedido", "urlhistory");
    }

    const enviarPedido = (observacion) => {

        if(validarInventario()){

            if(! errorImage){

                var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

                if(correoEnvio.match(mailformat)) {

                    let promises = [];
                    let responses = [];
                    let inventario = JSON.parse(localStorage.getItem("dataDistribucion"))

                    let numeroDocPadre = moment().format('YYYYMMDDHHmmssSSS');
                    
                    inventario.map((item, i) => {
                        if (item.productos.length > 0){

                            let data = [];
                            let count = 0;
                            let numeroDoc = moment().format('YYYYMMDDHHmmssSSS'+item.sucursal);

                            item.productos.map((itemP, i) => {
                                count++;
                                data.push({
                                    "NumeroDocPadre": numeroDocPadre,
                                    "NumeroDoc": numeroDoc,
                                    "CodigoCliente": codCliente,
                                    "Sucursal": item.sucursal,
                                    "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                                    "Precio": parseInt(itemP.Precio),
                                    "Iva": parseInt(itemP.Iva),
                                    "Posicion": count,
                                    "Cantidad": parseInt(itemP.Cantidad),
                                    "CodigoProducto": itemP.Codigo,
                                    "ValorDescuento": itemP.ValorDescuento,
                                    "Param1": itemP.Param1,
                                    "Param2": parseInt(itemP.Param2),
                                    "Param3": parseInt(itemP.Param3),
                                    "Param4": itemP.Param4,
                                    // "Param5": correoEnvio,
                                    "DescripcionParam1": itemP.Param1 ? "Porcentaje Descuento" : "",
                                    "DescripcionParam2": itemP.Param2 ? "Cantidad Descuento Aplicada" : "",
                                    "DescripcionParam3": itemP.Param3 ? "Codigo Descuento" : "",
                                    "DescripcionParam4": itemP.Param4 ? "Clase" : "",
                                    "DescripcionParam5": correoEnvio,
                                    "Descripcion" : observacion
                                })
                            });

                            if(data.length > 0){
                                let url = Constants.URL_BASE + Constants.WSPEDIDO + "?usuario=" + usuario;

                                promises.push(
                                    axios.post(url, {"ListaDetalle": data})
                                    .then(res => {
                        
                                        responses.push(res)
                        
                                        Object.keys(carrito).map((item, i) => {
                                            delete carrito[item];
                                        });
                        
                                        localStorage.setItem(codCliente, JSON.stringify(carrito));
                                        // props.history.replace("/agendamiento", "urlhistory");
                                    })
                                )
                            }
                            
                        }
                    });

                    Promise.all(promises).then(() => {

                        let data = {
                            "NumeroDocPadre": numeroDocPadre,
                            "Firma": imageData
                        }

                        let url = Constants.URL_BASE + Constants.WSENVIARCORREOPEDIDO

                        axios.post(url, data)
                        .then(res => {

                            alert(res.data.Mensaje ? res.data.Mensaje : "Pedido Almacenado Exitosamente");
                            
                            localStorage.removeItem("dataDistribucion");
                            props.history.replace("/agendamiento", "urlhistory");
                        });
                    });

                } else {
                    alert("Correo ingresado no válido");
                }
            } else {
                alert("Debe ingresar firma");
            }
        } else {
            alert("Debe distribuir todo el inventario");
        }
    }

    const validarInventario = () => {
        let inventario = JSON.parse(localStorage.getItem("dataDistribucion")) || [];

        let cantidadValidar = Object.keys(carrito).length;
        let validador = 0;

        Object.keys(carrito).map((item, i) => {

            let cantidadCarrito = 0
            let cantidadInventario = 0

            cantidadCarrito = parseInt(carrito[item]["cantidad"])

            inventario.map((itemI, i) => {

                if (itemI.productos.length > 0){
    
                    var indexSucursal = inventario.findIndex(p => p.sucursal == itemI.sucursal);

                    var indexProducto = inventario[indexSucursal].productos.findIndex(p => p.Codigo==item);
    
                    if(inventario[indexSucursal].productos[indexProducto]){
                        cantidadInventario += parseInt(inventario[indexSucursal].productos[indexProducto].Cantidad);
                    }
    
                }
            });

            if(cantidadCarrito == cantidadInventario) {
                validador++
            }

        });

        if(cantidadValidar == validador){
            return true;
        } else {
            return false;
        }
    }

    const handleDistribuir = (codProducto, item)  => {

        setItemDistribucion(item)
        setCodItemDistribucion(codProducto)

        setModalEstado(0);
        document.querySelector(".modal-distribucion-venta").classList.toggle("show-modal");
    }

    const handleObservaciones = () => {
        document.querySelector(".modal-observaciones").classList.toggle("show-modal");
    }

    const handlePedido = () => {
        handleObservaciones();
        // enviarPedido("");
    }

    const handleRedirect = item => {
        props.history.replace(item, "urlhistory");
    }

    useEffect(() => {

        setCarrito(JSON.parse(localStorage.getItem(codCliente)) || {});
        getIva();
        getTotal();
        setCarrito(getCantidad());

    }, [])

    return (
        <React.Fragment>

            <div className="navbar">
                <div className="navbar-header">
                    <div className="nav-header navbar-inicial" id="nav-hidden">
                        <div className="nav-content">
                            <div className="subnav">
                                <div className="back" onClick={() => handleRedirect("/carrito")} title="Atrás">
                                    <Back className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                </div>
                                <div className="nav-logo">
                                    <img 
                                        src={logo_login}
                                        onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                    </img>
                                </div>
                            </div>
                            <div className="navmenu">
                            </div>
                        </div>
                    </div> 
                </div> 
            </div> 
    
            <div className="pedido-list carrito">
                {carrito ? (
                    <div className="product-list">
                        {Object.keys(carrito).map((item, i) => (
                            <div className="item item-carrito" key={i}>
                                <img width="120"
                                        className="img-product"
                                        src={ Constants.URL_IMG_PRODUCTOS + item.toString().trim() + '.jpg'}
                                        onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}/>
                                <div className="start">
                                    
                                    <p className="codigo"><b>Codigo: </b>{item}</p>
                                    <p className="nombre"><b>{carrito[item].nombre}</b></p>
                                    <p className="precio">
                                        {Constants.copFormat(carrito[item].precio)}
                                    </p>
                                    <p className="regular">
                                        Precio de Referencia 
                                        {" " + Constants.copFormat(carrito[item].precio)}
                                    </p>
                                    <p style={{ color : "#e11223" }}><b>Cantidad a Asignar: {carrito[item].cantidad}</b></p>
                                </div>
                                <div className="end">
                                    <div className="action">

                                        { essucursal == "S" ? (
                                                <button className="distribuir" onClick={() => handleDistribuir(item, carrito[item])}>Distribuir</button>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>

            <div className="total">
                <div className="total-content">
                    <div className="title">
                        <p>PRELIQUIDACIÓN</p>
                    </div>
                    <hr/>
                    <div className="item">
                        <p className="opc">Subtotal:</p>
                        <p className="val">
                            {Constants.copFormat(total)}
                        </p>
                    </div>

                    <div className="item">
                        <p className="opc">Descuento:</p>
                        <p className="val">
                            {new Intl.NumberFormat("es-CO", {
                                style: "currency",
                                currency: "COP"
                            }).format(descuento_total)}
                        </p>
                    </div>

                    <div className="item">
                        <p className="opc">Iva:</p>
                        <p className="val">
                            {Constants.copFormat(iva)}
                        </p>
                    </div>
                    <div className="item">
                        <p className="opc">Total:</p>
                        <p className="val">
                            {Constants.copFormat((total - descuento_total + iva))}
                        </p>
                    </div>

                    <Fragment>

                        <hr/>

                        <pre>
                            { errorImage ? <div style={{color:"#e11223"}}>La firma es obligatoria</div> : false }
                        </pre>

                        <SignatureCanvas
                            canvasProps={{
                                width: 500,
                                height: 200,
                                style:{"border":"1px solid #000000"}
                            }}
                            minWidth={2}
                            maxWidth={3}
                            penColor="green"
                            ref={signatureRef}
                            onEnd={()=>(
                                saveSignature(signatureRef.current.getTrimmedCanvas().toDataURL("image/jpg"))
                            )}
                        />

                        <button className="btn-large"
                            style={{width:"30%", margin:"auto"}}
                            onClick={() => {

                            // console.log("imagen", imageData)
                            signatureRef.current.clear();
                            saveSignature(null);
                            setErrorImage(true);
                        }}> Limpiar </button>

                        <br />

                        <input 
                            type="email"
                            placeholder="E-mail"
                            onChange={e => setCorreoEnvio(e.target.value)}
                            value={correo}>
                        </input>

                    </Fragment>

                </div>
                
                <button type="submit" className="btn-large outline" onClick={() => handleRegresar()}>Regresar</button>
                <button type="submit" className="btn-large outline" onClick={() => handleCancelarPedido()}>Cancelar pedido</button>
                
                {maximoPedido ? (
                    <div className="carrito-validacion">
                        <div>
                            <img src={error} alt="icon plus" width="20px" height="20px"></img>
                        </div>
                        <div className="contenido">
                            <p><b>Cantidad maxima de pedidos superada</b></p>
                        </div>
                    </div>
                ) : (
                    <button type="submit" className="btn-large" onClick={() => handlePedido()}>Enviar pedido</button>
                )} 
            </div>

            <Tabbar item={5}/>
            <DistribucionVentaModal codCliente={codCliente} 
                itemDistribucion={itemDistribucion} 
                codItemDistribucion={codItemDistribucion} 
                />
            <ObservacionesModal enviarPedido={enviarPedido}  /> 
    </React.Fragment>
    )
}

export default withRouter(DistribucionInventario);