import React, { useState, useEffect } from "react";
import NavbarInicial from "../navbar/NavbarInicial";
import TabbarInicial from "../navbar/TabbarInicial";
import ProductoCatalogo from "./ProductoCatalogo";
import * as Constants from "../../constants/global";
import { ReactComponent as Search } from "../../assets/icon/search.svg";
import cuadricula from "../../assets/img/cuadricula.png";
import { withRouter } from "react-router-dom";

const Catalogo = (props) => {
  const [search, setSearch] = useState("");
  const [deboundcedSearch, setDeboundcedSearch] = useState();
  const handleCantidad = () => {};

  useEffect(() => {
    let sucursal = localStorage.getItem("sucursal");
    localStorage.removeItem(sucursal);
  });

  useEffect(() => {
    const timerId = setTimeout(() => {
      setSearch(deboundcedSearch);
    }, 1200);
    return () => {
      clearTimeout(timerId);
    };
  }, [deboundcedSearch]);

  return (
    <React.Fragment>
      <div className="navbar">
        <div className="navbar-header">
          <NavbarInicial item={1} />

          <div className="input-search">
            <input
              type="text"
              placeholder="Buscar producto Catalogo..."
              name="search"
              id="search"
              onChange={(e) => setDeboundcedSearch(e.target.value)}
              value={deboundcedSearch}
            ></input>
          </div>
        </div>
      </div>

      <ProductoCatalogo
        tipo={1}
        search={search}
        handleCantidad={handleCantidad}
      />

      <TabbarInicial item={1} />
    </React.Fragment>
  );
};

export default withRouter(Catalogo);
