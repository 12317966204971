import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import close from '../../assets/icon/close_grey.svg';
import Auth from '../../helpers/Auth';

import * as Constants from '../../constants/global';
import axios from 'axios';

const LoginModal = (props) => {

    const [clave, setClave] = useState();

    const handleClose = () => {
        document.querySelector(".modal-login").classList.remove("show-modal");
    }

    // const handleRecuperarClave = () => {
    //     document.querySelector(".modal-login").classList.remove("show-modal");
    //     document.querySelector(".modal-recuperacion").classList.toggle("show-modal");
    // }

    const handleLogin = () => {
        let url = Constants.URL_BASE + Constants.WSLOGINCLIENTE;

        let data = {
            "Usuario": props.nit,
            "Clave": clave.trim(),
            "TipoUsuario": props.tipoUsuario
        };

        axios.post(url, data)
        .then(res => {

            if(res.data.Login === "1") {

                document.querySelector(".modal-login").classList.remove("show-modal");

                localStorage.setItem("usuario", props.nit);
                localStorage.setItem("tipoUsuario", "cl");

                Auth.authenticate(() => {
                    props.history.push('/catalogo');
                })

            } else {
                alert("Nit o Clave Incorrectos")
            }
        });
    }
    return (
        <div className="modal modal-login">
            <div className="modal-content">       
                <div className="title">
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                    <p>Digite el código enviado al correo electronico: {props.correoCodigo}</p>
                </div>
                <div className="content sm default">
                    <input 
                        type="password"
                        placeholder="Código"
                        id="password"
                        onChange={e => setClave(e.target.value)}
                        value={clave}>
                    </input>
                </div>

                {/* <p className="recuperar-clave" onClick={() => handleRecuperarClave()}>Olvide mi clave</p> */}
                
                <div className="footer">
                    <button type="submit" className="btn-large" onClick={() => handleLogin()}>Ingresar</button>
                </div>
            </div>

        </div>
        
        
    )
}

export default withRouter(LoginModal);