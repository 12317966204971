import { QUANTITY_CLIENTS } from "../constants/global";

const INITIAL_STATE = {
  agendados: null,
  noAgendados: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case QUANTITY_CLIENTS:
      const cadena = action.payload.split("/");
      return {
        ...state,
        agendados: cadena[0],
        noAgendados: cadena[1],
      };
    default:
      return state;
  }
};
