import React, { useState, useEffect } from "react";

import Progress from "../util/Progress";
import axios from "axios";

import * as Constants from "../../constants/global";
import toast from "toasted-notes";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const EstadisticasAgendamiento = (props) => {
  let usuario = localStorage.getItem("usuario");

  const [loader, setLoader] = React.useState(false);
  const [estadisticasAgendamiento, setEstadisticasAgendamiento] = useState();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const loadDatos = () => {
    setLoader(true);

    let url =
      Constants.URL_BASE +
      Constants.WSESTADISTICASAGENDAMIENTO +
      "?usuario=" +
      usuario +
      "&fechaInicial=" +
      (props.fechaInicial ? props.fechaInicial : Constants.getDate(0, "-")) +
      "&fechaFinal=" +
      (props.fechaFinal ? props.fechaFinal : Constants.getDate(0, "-"));

    if (props.filtroUsuario) {
      url += "&filtroUsuario=" + props.filtroUsuario;
    }
    if (props.regional) {
      url += "&regional=" + props.regional;
    }
    if (props.agencia) {
      url += "&agencia=" + props.agencia;
    }
    if (props.zona) {
      url += "&zona=" + props.zona;
    }

    axios
      .get(url)
      .then((res) => {
        setLoader(false);
        setEstadisticasAgendamiento(res.data);
      })
      .catch((err) => {
        toast.notify("Error al cargar los datos de estadisticas");
      });
  };

  const exportToExcel = () => {
    let dataExcel = [];
    let fileName = "Estadisticas";

    Object(estadisticasAgendamiento).map((item, i) => {
      dataExcel.push({
        Usuario: item.usuario,
        Rol: item.rol,
        Nombre: item.nombre,
        Asignados: item.asignados,
        Agendados: item.agendados,
        "Sin Agendar": item.noAgendados,
        Efectividad: Math.round(item.efectividad, 2) + "%",
        Pedidos: item.efectivos,
        Pendientes: item.pendientes,
        "Drop Size": Constants.copFormat(item.dropsize),
        Sugerido: Constants.copFormat(item.sugerido),
        Total: Constants.copFormat(item.total),
      });
    });

    const ws = XLSX.utils.json_to_sheet(dataExcel);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  useEffect(() => {
    if (props.zona && props.filtroUsuario) {
      loadDatos();
    }
  }, [
    props.fechaInicial,
    props.fechaFinal,
    props.filtroUsuario,
    props.regional,
    props.agencia,
    props.zona,
  ]);

  return (
    <div className="main">
      <div className="cliente-list">
        <div className="product-list">
          {loader ? (
            <Progress color={"#007daf"} />
          ) : (
            <React.Fragment>
              {estadisticasAgendamiento ? (
                <div className="item" style={{ display: "block" }}>
                  <div className="exportar" style={{ display: "block" }}>
                    <button
                      className="btn-exportar"
                      onClick={(e) => exportToExcel()}
                    >
                      Exportar
                    </button>
                  </div>
                  <table
                    style={{ marginTop: "20px" }}
                    className="table-estadisticas-agendamiento"
                  >
                    <thead>
                      <tr>
                        <th>Usuario</th>
                        <th>Rol</th>
                        <th>Nombre</th>
                        <th>Asignados</th>
                        <th>Agendados</th>
                        <th>Sin Agendar</th>
                        <th>Efectividad</th>
                        <th>Pedidos</th>
                        <th>Pendientes</th>
                        <th>Drop Size</th>
                        <th>Sugerido</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {estadisticasAgendamiento.map((item, i) => (
                        <tr key={i}>
                          <td>{item.usuario}</td>
                          <td>{item.rol}</td>
                          <td>{item.nombre}</td>
                          <td>{item.asignados}</td>
                          <td>{item.agendados}</td>
                          <td>{item.noAgendados}</td>
                          <td>{Math.round(item.efectividad, 2)}%</td>
                          <td>{item.efectivos}</td>
                          <td>{item.pendientes}</td>
                          <td>{Constants.copFormat(item.dropsize)}</td>
                          <td>{Constants.copFormat(item.sugerido)}</td>
                          <td>{Constants.copFormat(item.total)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default EstadisticasAgendamiento;
