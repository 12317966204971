import {
  QUANTITY_CLIENTS,
  LOAD_PRODUCTS,
  DATA_PRODUCTS,
  CUSTOMER_PROFILE,
  LOAD_ALL_PRODUCTS,
  LOAD_DISCOUNTS,
} from "../constants/global";
import base from "../apis/base";

export const datosClientesAgendados = (url) => {
  return async (dispatch) => {
    const response = await base.get(`${url}`);
    dispatch({ type: QUANTITY_CLIENTS, payload: response.data });
  };
};

export const traerProductos = (url) => {
  return async (dispatch) => {
    const response = await base.get(`${url}`);
    dispatch({ type: LOAD_PRODUCTS, payload: response.data.result });
    dispatch({ type: DATA_PRODUCTS, payload: response.data });
  };
};

export const traerProductosTodos = (url) => {
  return async (dispatch) => {
    const response = await base.get(`${url}`);
    dispatch({ type: LOAD_ALL_PRODUCTS, payload: response.data.result });
  };
};

// export const configuracionProductos = (url) => {
//   return async (dispatch) => {
//     const response = await base.get(`${url}`);
//     dispatch({ type: DATA_PRODUCTS, payload: response.data });
//   };
// };

export const traerPerfilCliente = (url) => {
  return async (dispatch) => {
    const response = await base.get(`${url}`);
    dispatch({ type: CUSTOMER_PROFILE, payload: response.data });
  };
};

export const cargarDescuentos = (url) => {
  return async (dispatch) => {
    const response = await base.get(`${url}`);
    dispatch({ type: LOAD_DISCOUNTS, payload: response.data });
  };
};
