import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import close from "../../assets/icon/close_grey.svg";

import * as Constants from "../../constants/global";

const ConfirmationModal = (props) => {
  const [observacion, setObservacion] = useState();

  const handleCancelar = () => {
    document
      .querySelector(".modal-confirmation")
      .classList.remove("show-modal");
  };

  const handleEnviar = () => {
    props.handleEliminar(props.codigoEliminar, props.codigoEliminar);
    document
      .querySelector(".modal-confirmation")
      .classList.remove("show-modal");
  };

  return (
    <div className="modal-con modal-confirmation">
      <div className="modal-content">
        <div className="title">
          <p>Realmente desea eliminar el producto del carrito</p>
        </div>
        <div className="footer">
          <button
            type="submit"
            className="btn-large"
            onClick={() => handleEnviar()}
          >
            Eliminar
          </button>
          <button
            type="submit"
            className="btn-large outline"
            onClick={() => handleCancelar()}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ConfirmationModal);
