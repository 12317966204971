import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import * as Constants from "../../constants/global";
import { isMobile } from "./../../helpers/Device";

import logo_login from "../../assets/img/logo_interior.png";
import noimage from "../../assets/img/noimage.png";
import carrito from "../../assets/icon/carrito.svg";
import { ReactComponent as Back } from "../../assets/icon/back.svg";
import { ReactComponent as Logout_icon } from "../../assets/icon/logout_icon.svg";

const Navbar = (props) => {
  const [tipoUsuario, setTipoUsuario] = useState("");

  let empresa = JSON.parse(localStorage.getItem("empresa"));

  const handleRedirect = (item) => {
    props.history.replace(item, "urlhistory");
  };

  const handleBack = (item) => {
    props.history.goBack();
  };

  const handleLogOut = (item) => {
    props.history.push("/login");
  };

  window.onscroll = function () {
    if (isMobile()) {
      if (document.getElementById("nav-hidden")) {
        var currentScrollPos = window.pageYOffset;
        if (currentScrollPos > 20) {
          document.getElementById("nav-hidden").style.display = "none";
          document.getElementById("nav-hidden").style.opacity = "0";
          document.getElementById("nav-hidden").style.transform =
            "translate(9999px)";
        } else {
          document.getElementById("nav-hidden").style.display = "initial";
          document.getElementById("nav-hidden").style.opacity = "initial";
        }
      }
    }
  };

  useEffect(() => {
    setTipoUsuario(localStorage.getItem("tipoUsuario"));
  });

  return (
    <div className="nav-header" id="nav-hidden">
      <div className="nav-content">
        <div className="subnav">
          <div
            className="back"
            onClick={() => handleRedirect("/catalogo")}
            title="Regresar"
          >
            {/* <img src={back} alt="icon menu" width="15px" height="15px"></img> */}
            <Back
              className="svg-una-capa"
              style={{ width: "15px", height: "15px" }}
            />
          </div>
          <div
            className="back"
            onClick={() => handleLogOut()}
            title="Cerrar Sesión"
          >
            {/* <img src={back} alt="icon menu" width="15px" height="15px"></img> */}
            <Logout_icon
              className="svg-una-capa"
              style={{ width: "20px", height: "20px" }}
            />
          </div>
          <div className="nav-logo">
            <img
              src={logo_login}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = noimage;
              }}
            ></img>
          </div>
        </div>
        <div className="navmenu">
          <ul>
            <li>
              <a
                className={props.item == 1 ? "tab active" : "tab"}
                onClick={() => handleRedirect("/pedido")}
              >
                Pedido
              </a>
            </li>
            {/* <li>
                            <a  className={props.item == 2 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/sugerido')}>
                                    Sugerido
                            </a>
                        </li> */}
            {/* <li>
                            <a  className={props.item == 3 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/catalogo')}>
                                    Catálogo
                            </a>
                        </li> */}
            {/* <li>
                            <a className={props.item == 4 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/excel')}>
                                Excel
                            </a>
                        </li> */}
            {/* <li>
                            <a className={props.item == 6 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/historial')}>
                                Historial
                            </a>
                        </li> */}
            {/* <li>
                            <a className={props.item == 7 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/ultimosPedidos')}>
                                Historial
                            </a>
                        </li> */}
            {/* <li>
                            <a className={props.item == 8 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/cartera')}>
                                Cartera
                            </a>
                        </li>
                        <li>
                            <a className={props.item == 9 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/contactenos')}>
                                Contacto
                            </a>
                        </li>
                        <li>
                            <a className={props.item == 10 ? "tab active" : "tab"}
                                onClick={ () => handleRedirect('/ayuda')}>
                                Ayuda
                            </a>
                        </li> */}
            {tipoUsuario === "us" ? (
              <li>
                <a
                  className={props.item == 2 ? "tab active" : "tab"}
                  onClick={() => handleRedirect("/perfil")}
                >
                  Perfil
                </a>
              </li>
            ) : null}
            <li>
              <a
                className={props.item == 3 ? "tab active" : "tab"}
                onClick={() => handleRedirect("/descuentos")}
              >
                Descuentos
              </a>
            </li>
            <li>
              <a
                className={props.item == 4 ? "tab active" : "tab"}
                onClick={() => handleRedirect("/Excel")}
              >
                Cargue Plantilla
              </a>
            </li>
            <li>
              <a
                className={props.item == 5 ? "tab cart_active" : "tab cart"}
                onClick={() => handleRedirect("/carrito")}
              >
                <img
                  src={carrito}
                  alt="icon menu"
                  width="15px"
                  height="15px"
                ></img>
                <div className="cart-counter">{props.cantidad}</div>
                <p>
                  {Constants.copFormat(Math.round(props.totalNavbar))}
                  {/* {new Intl.NumberFormat("es-CO", {
                                        style: "currency",
                                        currency: "COP"
                                        }).format(props.totalNavbar)} */}
                </p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Navbar);
